@use "sass:map";

@import "./variables-bootstrap";
@import "bootstrap/scss/variables";

@import "./variables-bootstrap-legacy";

@import "./variables-bootstrap-vue";
@import "bootstrap-vue/src/variables";

// Color system

$primary-2: #f44f09;

$white-80: rgba($white, .8);
$black-20: rgba($black, .2);

$social-colors: (
    "facebook": #3b5998,
    "google": #db4437,
    "twitter": #1da1f2,
    "whatsapp": #25d366,
    "apple": $black,
    "rss": #f26522,
    "pinterest": #b70d1b,
    "link": $primary
);

$user-colors: (
    "default": $gray-500,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "blue": $blue,
    "purple": $purple,
);

// Grid columns

$grid-gutter-width-sm: map.get($spacers, 3);

// Components

$divider-bottom-margin-bottom: $spacer;
$divider-bottom-padding-bottom: $spacer;
$divider-bottom-width: $border-width;
$divider-bottom-color: $border-color;

// Typography

$font-family-sans-serif-default:    system-ui, -apple-system, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-xs:                      $font-size-base * .7333; // 11px
$font-weight-500: 500;


// Tooltips

$tooltip-arrow-size: .5rem;
