
@use "sass:map";

@import "./../scss/required";

.notification-gdpr {
    position: sticky;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
    order: 5;
    padding: $spacer * .75 0;
    background-color: $white;
    border-top: 1px solid $border-color;

    span {
        font-size: $small-font-size;
    }
}
